import { Skeleton } from "~/design-system/atom/skeleton";

export const Shimmer = () => {
    return (
        <div className="w-full items-center">
            <Skeleton className="h-1 w-[240px]" />
            <Skeleton className="mt-3 h-1 w-[200px]" />
            <Skeleton className="mt-3 h-1 w-[120px]" />
        </div>
    );
};
