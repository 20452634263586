// Disable eslint in this file
/* eslint-disable */
import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useRef } from "react";
import { z } from "zod";
import { moveToBottom } from "~/_website/ui/container/plaground/chat_box";
import { isSendingMessageAtom } from "../container/plaground/hooks";

const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/playground`);

url.protocol = url.protocol.replace("http", "ws").replace("https", "wss");

export const currentSessionId = atom<string | null>(null);
export const logsAtom = atom<z.infer<typeof ZLogItem>[]>([]);
export const chatConversationsAtom = atom<z.infer<typeof ZChatItem>[], any, any>(
    [],
    (get, set, payload: z.infer<typeof ZUpdateChatConversation>) => {
        if (payload.updateType === "reset") {
            set(chatConversationsAtom, payload.conversation);
        } else if (payload.updateType === "filter-out-loading") {
            const chatConversations = payload.conversation;
            const currentConversations: any = get(chatConversationsAtom).filter((item: any) => !item.isLoading);

            const currentConversationIds = currentConversations.map((item: any) => item.id);
            console.log("Current conv", currentConversationIds);
            console.log("Payload conv", payload.conversation);
            if (payload.conversation.some((item) => !!item.id && currentConversationIds.includes(item.id))) {
                const messageIdChat = payload.conversation.find((item) => !!item.id);

                if (messageIdChat) {
                    const index = currentConversations.findIndex((item: any) => item.id === messageIdChat.id);

                    currentConversations[index] = messageIdChat;
                    set(chatConversationsAtom, currentConversations);
                }
            } else {
                currentConversations.push(...chatConversations);
                set(chatConversationsAtom, currentConversations);
            }
        } else {
            const chatConversations = payload.conversation;
            const currentConversations: any = get(chatConversationsAtom);
            currentConversations.push(...chatConversations);
            set(chatConversationsAtom, currentConversations);
        }
    },
);

export const usePlaygroundSession = () => {
    const [sessionId, setSesionId] = useAtom(currentSessionId);
    const [chatConversations, _addChatConversation] = useAtom(chatConversationsAtom);
    const [, setLogs] = useAtom(logsAtom);
    const wsRef = useRef<WebSocket | null>(null);

    useEffect(() => {
        const ws = new WebSocket(String(url.toString()));

        wsRef.current = ws;
        ws.onopen = () => {
            console.log("WebSocket connection established");
            ws.send(
                JSON.stringify({
                    type: "new-session",
                }),
            );
        };
        ws.addEventListener("message", (event) => {
            const data = JSON.parse(event.data);

            if (data.type === "new_session") {
                setSesionId(data.session_id);
            } else if (data.type === "chat") {
                _addChatConversation({
                    conversation: data.payload,
                    updateType: "filter-out-loading",
                });
            } else if (data.type === "logs") {
                console.log("Logs", data.logs);
                setLogs((prev) => {
                    return [...prev, ...data.logs];
                });
            }
        });

        ws.onclose = () => {
            console.log("WebSocket connection closed");
        };
    }, []);

    const addChatConversation = useCallback(
        (payload: z.infer<typeof ZUpdateChatConversation>) => {
            _addChatConversation(payload);
            wsRef.current?.send(
                JSON.stringify({
                    type: "send.message",
                    payload: payload.conversation,
                    sessionId: sessionId,
                }),
            );
        },
        [sessionId, _addChatConversation],
    );

    return {
        chatConversations,
        sessionId,
        isLoading: false,
        hasLoadingChatItem: false,
        addChatConversation: addChatConversation,
    };
};

export const useAddSelectBoxMessage = (sessionId: string, chatConversations: any[], addChatConversation: any) => {
    // const {sessionId, chatConversations, addChatConversation} = usePlaygroundSession();
    const [, setIsSendingMessage] = useAtom(isSendingMessageAtom);

    const addSelectAnswer = useCallback(
        ({ value, type, id }: { value: string | string[]; type: "select" | "input" | "buttons" | "multi-select"; id: string }) => {
            console.log("Chat conversations", sessionId, chatConversations);
            const lastMessage = chatConversations.at(-1) as any;
            const newAnswer = lastMessage?.input.templateResponse.replace("{{value}}", value);

            setIsSendingMessage(true);

            if (type === "select" || type === "multi-select") {
                const newUserMessage = {
                    from: "user",
                    type: "text",
                    input: {
                        id,
                        value,
                        text: newAnswer,
                    },
                    text: newAnswer,
                    time: Date.now(),
                    isLoading: false,
                };

                const newAgentMessage = {
                    from: "agent",
                    type: "text",
                    text: lastMessage.text,
                    time: Date.now(),
                    isLoading: true,
                };

                addChatConversation({
                    conversation: [newUserMessage as any, newAgentMessage as any],
                });
            } else {
                requestAnimationFrame(() => {
                    moveToBottom();
                    setTimeout(moveToBottom, 300);
                });
            }

            setIsSendingMessage(false);

            requestAnimationFrame(() => {
                moveToBottom();
                setTimeout(moveToBottom, 300);
            });
        },
        [chatConversations],
    );

    const addMessages = (messages: z.infer<typeof ZChatItem>[]) => {
        addChatConversation({
            conversation: messages,
        });
    };

    const moveToBottomWithDelay = () => {
        requestAnimationFrame(() => {
            moveToBottom();
            setTimeout(moveToBottom, 500);
        });
    };

    const addInputAnswer = ({ message }: { message: string }) => {
        const chat = chatConversations;

        if (message.trim()) {
            const lastMessage = chat.at(-1) as z.infer<typeof ZChatItem>;
            const input = lastMessage.input as z.infer<typeof ZPlainInputSchema>;

            addMessages([
                {
                    from: "user",
                    type: "message",
                    text: message.trim(),
                    time: Date.now(),
                    isLoading: false,
                    input: {
                        id: input?.id || "", // Provide a fallback value
                        value: message.trim(), // Provide a fallback value
                        text: message.trim(), // Provide a fallback value
                    } as any,
                },
                {
                    from: "agent",
                    type: "message",
                    text: lastMessage.text,
                    time: Date.now(),
                    isLoading: true,
                },
            ]);

            const textAreaElement = document.querySelector("textarea")!;

            textAreaElement.value = "";
            moveToBottomWithDelay();
        }
    };

    return {
        addSelectAnswer,
        addInputAnswer,
        sessionId,
    };
};

const ZLogItem = z.object({
    type: z.enum(["text"]),
    text: z.string(),
    time: z.number(),
});

export const ZSelectInputSchema = z.object({
    id: z.string(),
    type: z.literal("select"),
    choices: z.array(
        z.object({
            id: z.string(),
            value: z.string(),
        }),
    ),
    highlightedValue: z.string().optional(),
    templateResponse: z.string(),
});

export const ZButtonInputSchema = z.object({
    id: z.string(),
    type: z.literal("buttons"),
    buttons: z.array(
        z.object({
            id: z.string(),
            value: z.string(),
            title: z.string(),
            url: z.string().optional(),
        }),
    ),
});

export const ZPlainInputSchema = z.object({
    id: z.string(),
    type: z.literal("textbox"),
    placeholder: z.string(),
});

export const ZChatItem = z.object({
    id: z.optional(z.string()),
    from: z.enum(["agent", "user"]),
    type: z.enum(["message", "input"]),
    text: z.string(),
    time: z.number().optional(),
    code: z
        .object({
            language: z.enum(["python", "shell"]),
            content: z.string(),
        })
        .optional(),
    isLoading: z.boolean().optional(),
    input: z.union([ZSelectInputSchema, ZPlainInputSchema, ZButtonInputSchema]).optional(),
    state: z.enum(["NOT_STARTED", "IN_PROGRESS", "COMPLETED", "ERROR"]).optional(),
    waitingStateTexts: z.array(z.string()).optional(),
    childrens: z.array(z.string()).optional(),
});

export const ZUpdateChatConversation = z.object({
    conversation: z.array(ZChatItem),
    updateType: z.union([z.literal("append"), z.literal("reset"), z.literal("filter-out-loading")]),
});

export const ZSessionLogsBody = z.object({
    logs: z.array(ZLogItem),
    id: z.string(),
});
