/* eslint-disable */
import { motion } from "framer-motion";
import { CheckCircle2 } from "lucide-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { z } from "zod";
import { ZButtonInputSchema, ZChatItem, ZSelectInputSchema, useAddSelectBoxMessage } from "~/_website/ui/api/session";
import { Shimmer } from "~/_website/ui/container/plaground/components/common";
import { Button } from "~/design-system/atom/button";
import { useToast } from "~/design-system/atom/use-toast";
import { LoadingIconPlain } from "~/ui/components/base/loadingIcon";
import { CodeBlockComponent } from "~/ui/library/components/ui/CodeBlockComponent";
import { PlaygroundSessionContext } from "../hooks";

interface SequentialTextProps {
    textArray: string[];
    interval: number;

    // in milliseconds
    childrens?: any;
}

const SequentialTextDisplay: React.FC<SequentialTextProps> = ({ textArray, interval }) => {
    const [, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;

                if (nextIndex < textArray.length) {
                    setCurrentText(textArray[nextIndex]!);
                    return nextIndex;
                }

                clearInterval(timer);
                return prevIndex;
            });
        }, interval);

        return () => clearInterval(timer);
    }, [textArray, interval]);

    useEffect(() => {
        if (textArray.length > 0) {
            setCurrentText(textArray[0]!);
        }
    }, [textArray]);

    return currentText;
};

export const ChatItemMessage = ({ sessionId, chatConversations, addChatConversation, chatItem }: any) => {
    const isCompletedTask = chatItem.state === "COMPLETED";
    const isErrorTask = chatItem.state === "ERROR";
    const isWaitingState = ["NOT_STARTED", "IN_PROGRESS"].includes(chatItem.state!);
    const { type } = chatItem;
    const input = chatItem.input as z.infer<typeof ZSelectInputSchema>;
    const buttonsInput = chatItem.input as z.infer<typeof ZButtonInputSchema>;

    const { addSelectAnswer } = useAddSelectBoxMessage(sessionId, chatConversations, addChatConversation);
    const { toast } = useToast();

    const RenderChecbox = useCallback(() => {
        const choices = input?.choices;
        if (!choices) return null;

        return (
            <div className="mt-4 flex gap-2">
                {choices.map((choice: any, index: number) => {
                    return (
                        <Button
                            size={32}
                            variant={input.highlightedValue === choice.value ? "default" : "plain"}
                            key={index}
                            onClick={() => {
                                if (input.highlightedValue === choice.value) {
                                    toast({
                                        title: "You already selected this option",
                                        description: "Please select another option",
                                    });
                                    return;
                                }

                                addSelectAnswer({
                                    id: input.id,
                                    value: choice.value,
                                    type: "select",
                                });
                            }}
                        >
                            {choice.value}
                        </Button>
                    );
                })}
            </div>
        );
    }, []);

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const RenderMultiInputCheckbox = useCallback(() => {
        const choices = input?.choices;
        if (!choices) return null;

        const handleCheckboxChange = (choiceValue: string) => {
            let updatedSelectedValues = [];

            if (selectedValues.includes(choiceValue)) {
                updatedSelectedValues = selectedValues.filter((value) => value !== choiceValue);
            } else {
                updatedSelectedValues = [...selectedValues, choiceValue];
            }

            setSelectedValues(updatedSelectedValues);
            // addSelectAnswer({ id: input.id, value: updatedSelectedValues, type: "multi-select" });
        };

        return (
            <div className="mt-4 flex flex-wrap gap-2">
                {choices.map((choice: any, index: number) => {
                    return (
                        <label key={index} className="flex items-center gap-2">
                            <input
                                type="checkbox"
                                checked={selectedValues.includes(choice.value)}
                                onChange={() => handleCheckboxChange(choice.value)}
                            />
                            {choice.value}
                        </label>
                    );
                })}
                <div className="mt-2">
                    <Button
                        size={32}
                        variant={"default"}
                        onClick={() => {
                            addSelectAnswer({
                                id: input.id,
                                value: selectedValues,
                                type: "multi-select",
                            });
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    }, [input, selectedValues]);

    const RenderButton = useCallback(() => {
        const buttons = buttonsInput?.buttons;
        if (!buttons) return null;

        return (
            <div className="mt-4 flex gap-2">
                {buttons.map((button: any, index: number) => {
                    return (
                        <Button
                            size={32}
                            variant={"default"}
                            key={index}
                            onClick={() => {
                                if (button.url) {
                                    window.open(button.url, "_blank");
                                } else {
                                    addSelectAnswer({
                                        id: button.id,
                                        value: button.value,
                                        type: "buttons",
                                    });
                                }
                            }}
                        >
                            {button.title}
                        </Button>
                    );
                })}
            </div>
        );
    }, [buttonsInput]);

    return (
        <>
            <div className="leading-[1.7]">
                <div className="flex flex-wrap items-center">
                    {isWaitingState ? <SequentialTextDisplay textArray={chatItem.waitingStateTexts!} interval={1500} /> : chatItem.text}{" "}
                    {isWaitingState ? (
                        <LoadingIconPlain size={32} />
                    ) : isCompletedTask ? (
                        <CheckCircle2
                            style={{
                                border: "white",
                                fill: "green",
                                marginLeft: 4,
                            }}
                            size={18}
                        />
                    ) : isErrorTask ? (
                        <LoadingIconPlain size={32} />
                    ) : null}
                </div>
                {type === "input" && input?.type === "select" && <RenderChecbox />}
                {/*@ts-ignore*/}
                {type === "input" && input?.type === "multi-select" && <RenderMultiInputCheckbox />}
                {/*@ts-ignore*/}
                {type === "input" && input?.type === "textbox" && <RenderInput />}
                {/*@ts-ignore*/}
                {type === "input" && buttonsInput?.type === "buttons" && !(isCompletedTask || isErrorTask) && <RenderButton />}
                {/* {childItems} */}
            </div>
            {chatItem.childrens?.length && (
                <ol className="pl-6">
                    {chatItem.childrens.map((childItem: any, index: number) => {
                        return (
                            <li className="mt-3" key={index}>
                                ◼ <span className="ml-1">{childItem}</span>
                            </li>
                        );
                    })}
                </ol>
            )}
            {chatItem?.code ? (
                <CodeBlockComponent
                    text={chatItem.code.content}
                    language={chatItem.code.language}
                    style={{
                        marginTop: 20,
                    }}
                />
            ) : null}
        </>
    );
};

export const ChatItem = ({
    chatItem,
    index,
    childItems,
}: {
    chatItem: z.infer<typeof ZChatItem>;
    sessionId: string;
    index: number;
    childItems?: any;
}) => {
    const { sessionId, chatConversations, addChatConversation } = useContext(PlaygroundSessionContext) as any;
    const isWaitingState = ["NOT_STARTED", "IN_PROGRESS"].includes(chatItem.state!);
    const isCompletedTask = chatItem.state === "COMPLETED";
    const isErrorTask = chatItem.state === "ERROR";

    const isAgent = chatItem.from === "agent";
    const { isLoading, type } = chatItem;

    const formattedTime = new Date(chatItem.time!).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    });

    const input = chatItem.input as z.infer<typeof ZSelectInputSchema>;
    const buttonsInput = chatItem.input as z.infer<typeof ZButtonInputSchema>;

    const { addSelectAnswer } = useAddSelectBoxMessage(sessionId, chatConversations, addChatConversation);

    const { toast } = useToast();

    const RenderChecbox = useCallback(() => {
        const choices = input?.choices;
        if (!choices) return null;

        return (
            <div className="mt-4 flex gap-2">
                {choices.map((choice: any, index: number) => {
                    return (
                        <Button
                            size={32}
                            variant={input.highlightedValue === choice.value ? "default" : "plain"}
                            key={index}
                            onClick={() => {
                                if (input.highlightedValue === choice.value) {
                                    toast({
                                        title: "You already selected this option",
                                        description: "Please select another option",
                                    });
                                    return;
                                }

                                addSelectAnswer({
                                    id: input.id,
                                    value: choice.value,
                                    type: "select",
                                });
                            }}
                        >
                            {choice.value}
                        </Button>
                    );
                })}
            </div>
        );
    }, []);

    const RenderButton = useCallback(() => {
        const buttons = buttonsInput?.buttons;
        if (!buttons) return null;

        return (
            <div className="mt-4 flex gap-2">
                {buttons.map((button: any, index: number) => {
                    return (
                        <Button
                            size={32}
                            variant={"default"}
                            key={index}
                            onClick={() => {
                                if (button.url) {
                                    window.open(button.url, "_blank");
                                } else {
                                    addSelectAnswer({
                                        id: button.id,
                                        value: button.value,
                                        type: "buttons",
                                    });
                                }
                            }}
                        >
                            {button.title}
                        </Button>
                    );
                })}
            </div>
        );
    }, [buttonsInput]);

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const RenderMultiInputCheckbox = useCallback(() => {
        const choices = input?.choices;
        if (!choices) return null;

        const handleCheckboxChange = (choiceValue: string) => {
            let updatedSelectedValues = [];

            if (selectedValues.includes(choiceValue)) {
                updatedSelectedValues = selectedValues.filter((value) => value !== choiceValue);
            } else {
                updatedSelectedValues = [...selectedValues, choiceValue];
            }

            setSelectedValues(updatedSelectedValues);
            // addSelectAnswer({ id: input.id, value: updatedSelectedValues, type: "multi-select" });
        };

        return (
            <div className="mt-4 flex flex-wrap gap-2">
                {choices.map((choice: any, index: number) => {
                    return (
                        <label key={index} className="flex items-center gap-2">
                            <input
                                type="checkbox"
                                checked={selectedValues.includes(choice.value)}
                                onChange={() => handleCheckboxChange(choice.value)}
                            />
                            {choice.value}
                        </label>
                    );
                })}
                <div className="mt-2">
                    <Button
                        size={32}
                        variant={"default"}
                        onClick={() => {
                            addSelectAnswer({
                                id: input.id,
                                value: selectedValues,
                                type: "multi-select",
                            });
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    }, [input, selectedValues]);

    const RenderInput = useCallback(() => {
        useEffect(() => {
            document.querySelector("textarea")?.focus();
        }, []);
        return <div className="text-13 mt-4 flex gap-2">Please enter the value</div>;
    }, []);

    return (
        <motion.div
            initial={{
                opacity: 1,
                y: 50,
            }}
            animate={{
                opacity: 1,
                y: 0,
            }}
            transition={{
                duration: 0.3,
                type: "spring",
                bounce: 0,
                damping: 40,
                stiffness: 600,
            }}
            key={index}
            className="flex w-full items-start justify-between gap-2 text-[13px] font-[500] leading-none tracking-[.35px] text-[#e0e0e0cd]"
        >
            <div className="flex items-start gap-1 md:gap-2">
                <img
                    src={isAgent ? "/assets/images/website/bot.png" : "https://avatars.githubusercontent.com/u/6849438?v=4"}
                    alt="loading"
                    className="h-[36px] w-[36px] rounded-full border-[1px] border-[#4f4f4f9f] object-cover "
                />
                <div className="ml-4 flex min-w-[52px] flex-col gap-3 text-[14px] font-[500] text-[#ffffffcd] md:ml-2 md:flex-col md:gap-2">
                    <div className=" min-w-[56px] text-[13px] font-[500] text-[#ffffffcd] md:min-w-[unset]">
                        {isAgent ? "agent" : "user"} :
                    </div>
                    <div>
                        {isLoading ? (
                            <Shimmer />
                        ) : (
                            <>
                                <div className="leading-[1.7]">
                                    <div className="flex flex-wrap items-center">
                                        {isWaitingState ? (
                                            <SequentialTextDisplay textArray={chatItem.waitingStateTexts!} interval={1500} />
                                        ) : (
                                            chatItem.text
                                        )}{" "}
                                        {isWaitingState ? (
                                            <LoadingIconPlain size={32} />
                                        ) : isCompletedTask ? (
                                            <CheckCircle2
                                                style={{
                                                    border: "white",
                                                    fill: "green",
                                                    marginLeft: 4,
                                                }}
                                                size={18}
                                            />
                                        ) : isErrorTask ? (
                                            <LoadingIconPlain size={32} />
                                        ) : null}
                                    </div>
                                    {type === "input" && input?.type === "select" && <RenderChecbox />}
                                    {/*@ts-ignore*/}
                                    {type === "input" && input?.type === "multi-select" && <RenderMultiInputCheckbox />}
                                    {/*@ts-ignore*/}
                                    {type === "input" && input?.type === "textbox" && <RenderInput />}
                                    {type === "input" && buttonsInput?.type === "buttons" && !(isCompletedTask || isErrorTask) && (
                                        <RenderButton />
                                    )}
                                </div>
                                <div>
                                    {chatItem.childrens?.length && (
                                        <ol>
                                            {chatItem.childrens.map((childItem: any, index: number) => {
                                                return (
                                                    <li className="mt-2" key={index}>
                                                        {childItem}
                                                    </li>
                                                );
                                            })}
                                        </ol>
                                    )}
                                    {chatItem?.code ? (
                                        <CodeBlockComponent
                                            text={chatItem.code.content}
                                            language={chatItem.code.language}
                                            style={{
                                                marginTop: 20,
                                            }}
                                        />
                                    ) : null}
                                </div>
                                {childItems?.map((childItem: any, index: number) => {
                                    return (
                                        <div className="mt-2" key={index}>
                                            <ChatItemMessage
                                                key={index}
                                                sessionId={sessionId}
                                                chatConversations={chatConversations}
                                                addChatConversation={addChatConversation}
                                                chatItem={childItem}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex min-w-[70px] items-start text-right text-[10px]">{formattedTime}</div>
        </motion.div>
    );
};
