import React from "react";
import { CopyBlock, dracula, github } from "react-code-blocks";

export const CodeBlockComponent = ({ text, language, style }: { text: string; style?: Record<string, unknown>; language: string }) => {
    return (
        <CopyBlock
            text={text}
            language={language}
            customStyle={{
                borderRadius: "16px",
                border: "1px solid #979797",
                background: "#101010",
                padding: "16px 12px",
                overflowX: "auto",
                ...style,
            }}
            codeContainerStyle={{
                background: "#101010",
                maxWidth: "400px",
                display: "block !important",
            }}
            codeBlockStyle={{
                background: "#101010",
                overflowX: "auto",
                maxWidth: "400px",
            }}
            theme={{
                ...dracula,
                backgroundColor: "#101010",
            }}
            wrapLongLines={true}
        />
    );
};
