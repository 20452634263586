import { css } from "@emotion/css";

export const dustPatternCSS = css`
    background: url("/assets/images/global/dust.png") repeat center center;
    background-size: 24px;
    z-index: 0;
`;
export const gridPatternCSS = css`
    background: url("/assets/images/global/pattern.svg") repeat center center;
    background-size: 24px;
    opacity: 0.9;
    z-index: 0;
`;
export const gradientBackgroundCSS = css`
    background:
        0% 0% / 100px 100px repeat,
        linear-gradient(180deg, #000000 0%, #050505 73.91%);
`;

export const plainBlackBackgroundCSS = css`
    background: #f7f7f7;
`;

export const scrollBarStyle = css`
    scrollbar-color: #ffffff17 transparent;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 10px;
    }
`;

export const linkCSS = css`
    color: #ebebebec;
    transition: color 0.18s linear;
    :hover {
        color: #5d78ff;
    }
`;
