import * as React from "react";

import { cn } from "~/utils/cn";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => (
    <textarea
        className={cn(
            "border-input flex min-h-[48px] w-full  rounded-md border border-grey-300 px-3 py-2 text-sm placeholder:text-grey-400     focus:border-[#6e1bd8] focus:outline-none focus:ring-[1px]  focus:ring-[#F5EEFF] disabled:cursor-not-allowed disabled:opacity-80",
            className,
        )}
        ref={ref}
        {...props}
    />
));

Textarea.displayName = "Textarea";

export { Textarea };
