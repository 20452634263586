import { atom } from "jotai";
import { createContext } from "react";

export const isSendingMessageAtom = atom<boolean>(false);

export const PlaygroundSessionContext = createContext({
    chatConversations: [] as Array<unknown>,
    sessionId: null,
    hasLoadingChatItem: false,
    isLoading: false,
    addChatConversation: (_payload: unknown) => {
        // eslint-disable-next-line no-console
        console.log("addChatConversation", _payload);
    },
});
