// @ts-ignore
import * as Sentry from "@sentry/react";
import React from "react";

interface ErrorBoundaryProps {
    fallback: React.ReactNode;
    children?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state: { hasError: boolean };

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: unknown) {
        return { hasError: true };
    }

    componentDidCatch(error: unknown, info: unknown) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        console.error(error, info);

        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}
