/* eslint-disable */
import { css } from "@emotion/css";
import clsx from "clsx";
import { SendHorizonalIcon } from "lucide-react";
import React, { useEffect, useMemo, useRef } from "react";
import { usePlaygroundSession } from "~/_website/ui/api/session";
import { Shimmer } from "~/_website/ui/container/plaground/components/common";
import { Textarea } from "~/design-system/atom/textarea";
import { scrollBarStyle } from "~/ui/style/common";
import { useAddSelectBoxMessage } from "../../api/session";
import { ChatItem } from "./components/chat_item";
import { PlaygroundSessionContext } from "./hooks";

export const moveToBottom = () => {
    const chatMessageContainer = document.querySelector("#chat-message-container");

    chatMessageContainer?.scrollBy({
        top: chatMessageContainer?.scrollHeight,
        behavior: "smooth",
    });
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = React.useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window?.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};
const useGroupedChatConversations = (sessionId: string, chatConversations: any[]) => {
    return React.useMemo(() => {
        const groupedConversations: any[][] = [];
        let previousMessageFrom: string | null = null;
        let group: any[] = [];

        for (const [index, message] of chatConversations.entries()) {
            if (message.from === previousMessageFrom) {
                group.push(message);
            } else {
                if (group.length > 0) {
                    groupedConversations.push(group.slice());
                    group = [];
                }

                group.push(message);
            }

            previousMessageFrom = message.from;

            if (index === chatConversations.length - 1 && group.length > 0) {
                groupedConversations.push(group.slice());
            }
        }

        return groupedConversations.map((group, index) => {
            if (group.length > 1) {
                const childItems = group.slice(1);
                return <ChatItem sessionId={sessionId!} key={index} chatItem={group[0]} index={index} childItems={childItems} />;
            }

            return <ChatItem sessionId={sessionId!} key={index} chatItem={group[0]} index={index} />;
        });
    }, [chatConversations]);
};

export const ChatBox = () => {
    const [message, setMessage] = React.useState("");
    const { chatConversations: chat, sessionId, hasLoadingChatItem, isLoading, addChatConversation } = usePlaygroundSession();
    const conversationGroups = useGroupedChatConversations(sessionId!, chat);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const windowSize = useWindowSize();

    const { addInputAnswer } = useAddSelectBoxMessage(sessionId!, chat, addChatConversation);

    // Add this outside of this component and in common hooks to prevent props drilling
    const sendMessage = () => {
        if (hasLoadingChatItem || isLoading) {
            alert("Please wait for the previous message to finish loading");
            return;
        }

        if (message.trim()) {
            addInputAnswer({
                message: message.trim(),
            });
        }
    };

    const chatWidth = useMemo(() => {
        const chatBox = document.getElementById("chat");
        return chatBox?.offsetWidth || 0;
    }, [windowSize]);

    const playgroundHeight = useMemo(() => {
        const topSectionRef = document.querySelector(".top-section")!;
        // @ts-expect-error
        const topSectionHeight = topSectionRef?.offsetHeight || 0;

        const isMobile = windowSize.width < 768;

        return `calc(100svh - ${topSectionHeight + (isMobile ? 120 : 0)}px)`;
    }, [windowSize]);

    // const chatConversations = useMemo(() => {
    //     return (chat.map((chatItem, index) => <ChatItem sessionId={sessionId!} key={index} chatItem={chatItem} index={index} />));
    // }, [chat, sessionId]);
    const isDisabledInput = useMemo(() => {
        console.log("chat", chat);
        return chat.length > 0 && chat.at(-1)!.input?.type === "select";
    }, [chat]);

    const isWaitingForAnswer = useMemo(() => {
        return hasLoadingChatItem || isLoading;
    }, [chat]);

    return (
        <PlaygroundSessionContext.Provider
            value={{
                sessionId: sessionId as any,
                chatConversations: chat,
                // @ts-expect-error
                addChatConversation: addChatConversation,
                hasLoadingChatItem,
                isLoading,
            }}
        >
            <div
                id="chat"
                className={clsx(
                    "flex h-full max-h-full flex-col border-l-[.5px] border-[#1c1c1c9f] pl-6 pr-6 pt-8 md:border-l-0 md:px-2 md:pl-0 md:pt-4 md:pt-4",
                )}
            >
                <div
                    className={clsx(
                        "mg flex flex-col gap-10 overflow-scroll pb-3 pr-6 md:gap-10 md:px-2 md:pr-0",
                        scrollBarStyle,
                        css`
                            height: ${playgroundHeight};
                        `,
                    )}
                    id="chat-message-container"
                >
                    <div className="text-[14.5px] font-[600] leading-none tracking-[.35px] text-[#e0e0e0cd]">CHAT</div>
                    {/* <CodeBlockComponent
            text={`curl -X POST "https://hermes-production-6901.up.railway.app/api/user/create/{{email}}"
                -H "X-API-KEY: {{apiToken}}"`}
            language="bash"
        /> */}
                    {isLoading ? <Shimmer /> : conversationGroups}
                    <div className="min-h-[64px] md:min-h-[-0px]"></div>
                </div>
                <div
                    className={clsx(
                        `md:initial fixed bottom-[10px] w-full pb-1 pr-8 md:pr-0`,
                        css`
                            width: ${chatWidth}px;
                        `,
                    )}
                >
                    <Textarea
                        ref={textAreaRef}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && message.length > 0) {
                                sendMessage();
                                e.preventDefault();
                            }
                        }}
                        disabled={isWaitingForAnswer || isDisabledInput}
                        placeholder={
                            isDisabledInput
                                ? "Select an option above"
                                : isWaitingForAnswer
                                  ? "Waiting for an response from the agent..."
                                  : "Type your message here"
                        }
                        title={
                            isDisabledInput
                                ? "Select an option above"
                                : isWaitingForAnswer
                                  ? "Waiting for an response..."
                                  : "Type your message here"
                        }
                        className="!h-[20px] min-h-[60px] w-full max-w-[100%] resize-none rounded-[16px] border border-[#303030e3] bg-[#121212fb] px-3 !pt-[20px] pl-4 text-[#fff]"
                    />
                    <SendHorizonalIcon
                        className="absolute right-12 top-[24px] h-[16px] w-[16px] text-[#fff] md:right-6"
                        onClick={sendMessage}
                    />
                </div>
            </div>
        </PlaygroundSessionContext.Provider>
    );
};
