import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "~/utils/cn";

const buttonVariants = cva(
    " inline-flex transition-all items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-80",
    {
        variants: {
            variant: {
                default:
                    "!font-[500]   bg-[#7a1fef] cursor-pointer w-full   text-[#fff] hover:bg-gradient-to-b hover:brightness-90 transition text-[#fff]",
                plain: "!font-[500]  text-black-400  bg-transparent border-[1px] border-[#C6C6C6]",
                black: "!font-[500]  text-[#fff]   border-[1px] border-[#222] bg-[#222] !text-[#fff] text-black-100",
            },
            size: {
                default: "h-11 text-[12.5px] rounded-[14px] px-3 text-[13px] transition",
                24: "h-6 rounded-lg px-3 text-[12.5px] font-normal tracking-[.3px] ",
                28: "h-7 rounded-[12px] px-3 text-[13px] font-[500]",
                36: "h-9 rounded-[12px] px-3 text-[13px] font-normal tracking-[.3px] ",
                32: "h-8 rounded-[12px] px-3 text-[13px] font-normal tracking-[.3px] ",
                40: "h-10 rounded-[12px] px-4 text-[13.5px] font-normal tracking-[.3px] ",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = "Button";

export { Button, buttonVariants };
