import { AnalyticsBrowser } from "@segment/analytics-next";
import posthog from "posthog-js";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import { getCookie, setCookie } from "./cookie";

const analytics = AnalyticsBrowser.load(
    { writeKey: "hbf4bfqyIUXHcjwrLRApMM3LMa87uDeh" },
    {
        cookie: {
            domain:
                typeof window !== "undefined"
                    ? window.location.hostname.includes("composio.dev")
                        ? ".composio.dev"
                        : window.location.hostname
                    : undefined,
        },
        storage: {
            stores: ["cookie", "memory"],
        },
        // Specific Storage Priority
        user: {
            storage: {
                stores: ["cookie", "memory"],
            },
        },
        group: {
            storage: {
                stores: ["cookie", "memory"],
            },
        },
        integrations: {
            "Segment.io": {
                apiHost: "composio_s_1.composio.dev/v1",
                protocol: "https",
            },
        },
    },
);

export class ANALYTICS {
    static track(event: string, properties: Record<string, unknown>) {
        const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

        if (IS_DEV) {
            console.log("🎟️ track", event, properties);
        }

        analytics.track(event, {
            ...properties,
            isDev: IS_DEV,
        });
    }

    static async identify(id: string, properties: Record<string, unknown> = {}) {
        const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

        if (IS_DEV) {
            console.log("🎟️ identify", id, properties);
        }
        posthog.identify(id, properties);
        await analytics.identify(
            id,
            {
                ...properties,
                isDev: IS_DEV,
            },
            {},
        );
    }

    static async reset() {
        const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

        if (IS_DEV) {
            console.log("🎟️ reset");
        }
        posthog.reset(true);
        await analytics.reset();
    }

    static page(category: string, name: string, properties: Record<string, unknown>) {
        const IS_DEV = !window?.location?.hostname?.includes("composio.dev");

        if (IS_DEV) {
            console.log("🎟️ page", category, name, properties);
        }

        analytics.page(category, name, {
            ...properties,
            isDev: IS_DEV,
        });
    }
}

export class USER_ANALTICS_ID {
    static getUserId() {
        const userId = getCookie("composio_analytics_id");
        if (userId) {
            return userId;
        }

        return USER_ANALTICS_ID.generateUserId();
    }

    static generateUserId() {
        const userId = uuidv4();
        setCookie("composio_analytics_id", userId, 30);

        return userId;
    }

    static resetUserID() {
        setCookie("composio_analytics_id", "", 0);
    }
}
