export const getBaseUrl = () => {
    if (typeof window !== "undefined") return "";

    // browser should use relative url
    if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;

    // SSR should use vercel url
    return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

export const BACKEND_URL = process.env.NEXT_PUBLIC_API_URL || "http://localhost:9900";

export const IS_DEV = typeof window !== "undefined" && !window.location.hostname.includes("composio.dev");
